/* RevenueChart.css */

.chart-container {
    width: 100%;
    height: 600px; /* Adjust height as needed */
    margin: 20px 0;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.chart {
    width: 100%; /* Adjust width to fit the container */
    height: 100%; /* Ensure it takes the full height of the container */
}

.stats-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.stats {
    width: 45%;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.stats.left {
    margin-right: 5%;
}

.stats.right {
    margin-left: 5%;
}

.green-text {
    color: green;
}

.orange-text {
    color: rgb(219, 132, 1);
}

.red-text {
    color: red;
}
/* App.css */

/* Reset or normalize styles */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Global styles */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5; /* Background color for the entire app */
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.header {
  background-color: #fff; /* Change header background to white */
  color: #333; /* Text color for header */
  padding: 10px 0;
  text-align: center;
}

.footer {
  background-color: #fff; /* Change footer background to white */
  color: #333; /* Text color for footer */
  padding: 10px 0;
  text-align: center;
}

.main-content {
  width: 100%;
  padding: 20px;
  background-color: #fff; /* Background color for main content */
}
